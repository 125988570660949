
let department = JSON.parse(localStorage.getItem("userInfo"));
let editShow = false;
if(department.info.department_id == 3 && department.info.department_id_two != 28){
	editShow = true
}
if(department.info.department_id == 32){
	editShow = true
}
if(department.info.department_id == 4){
	editShow = true
}

export default {
  props: ["titleName","imgSrc"],
  setup(props) {
    return{
      props,
      editShow
    }
  },
};
